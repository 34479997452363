body {
  background: #d9eaff;
}
.App {
  text-align: center;
  font-family: "SF Pro Display", sans-serif;
  // background: radial-gradient(#4248d8, rgba(82, 213, 255, 0.64));
}

.flex-container {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-container {
  padding: 20px;
}

.desc {
  color: rgba(9, 13, 78, 0.5);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
  z-index: 9999;
}

#sitin-logo {
  width: 106px;
  height: 32px;
  margin-left: 64px;
  margin-top: 6px;

  @media screen and (max-width: 767px) {
    margin-left: 30px;
  }
}

#header-download-button-wrapper {
  padding-top: 4px;
  padding-right: 30px;
  @media screen and (max-width: 767px) {
    display: none; // do not display download button on mobile devices
  }
}

.download-button {
  height: 48px;
  padding: 4px 48px;
  border-radius: 8px;
  background: #057cff;
  border: #057cff;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.subscribe-button {
  height: 48px;
  padding: 4px 48px;
  border-radius: 8px;
  background: linear-gradient(to right, #007bff, #ff00ff);
  border: none;
  font-size: 16px;
  transition: background 0.3s ease;
  color: #fff;
  font-weight: 700;
}

#bg-video {
  position: fixed;
  object-fit: none;
  z-index: -1;

  video {
    width: 80%;
  }

  @media screen and (max-width: 767px) {
    //   display: none;
  }
}

#top-content {
  position: relative;
  margin-top: 110px;
  padding: 0 20%;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 90px;
  }
}

#top-content-h1 {
  color: #090d4e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 1000;
  line-height: normal;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
  // text-shadow: 0px 1px, 1px 0px, 1px 1px;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
  }
}

#banner-download-button-container {
  margin-top: 20px;
  // @media screen and (max-width: 767px) {
  //   display: none; // do not display download button on mobile devices
  // }
}

#subscribe-user-email-button-container {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 20px;
  }
}

#works-with {
  color: #090d4e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  margin-top: 32px;
  margin-bottom: 12px;
}

#works-with-app {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.works-with-app-container {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);

  @media screen and (max-width: 767px) {
    background: white;
    border-radius: 60px;
    padding: 3px 12px;
  }
}

.works-with-app-icon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #fff;
  margin-right: 8px;
  z-index: 10;
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}

.works-with-app-icon-text {
  color: #090d4e;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: 16px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

#page-content {
  .content-row {
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 767px) {
      // for phone screens
      flex-wrap: wrap;
    }
  }
  .content-row:nth-child(odd) {
    @media screen and (max-width: 767px) {
      // on phone screens, we need to show every image on top
      flex-direction: column-reverse;
    }
  }
  .text-container {
    flex: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: #090d4e;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: 767px) {
        margin: 10px 0;
        font-size: 20px;
        text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
      }
    }

    .text-desc {
      color: #090d4e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.5;
    }
  }

  .video-container2 {
    flex: 60%;
    margin: 36px 0;
    video {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
      padding: 0 20px;
      video {
        max-width: 660px;
      }
    }
  }
  .rounded-video {
    border-radius: 24px;
    @media screen and (max-width: 767px) {
      border-radius: 8px;
    }
  }
  // .rounded-image {
  //   border-radius: 3px;
  //   @media screen and (max-width: 767px) {
  //     border-radius: 24px;
  //   }
  // }
}

#tile-title {
  margin: 144px 30px 72px 30px;
  color: #090d4e;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 1000;
  line-height: normal;

  @media screen and (max-width: 767px) {
    margin: 60px 8px 30px 8px;
    font-size: 20px;
    text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
  }
}

.tiles-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // gap: 16px;
  // padding: 16px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
  }
}

.tile {
  margin: 0 8px;
  padding: 24px 24px 0 24px;
  margin-bottom: 32px;
  max-width: 288px;
  border-radius: 24px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);

  @media screen and (max-width: 767px) {
    // max-width: 100px;
    width: 40%;
    margin: 4px;
    padding: 8px 8px 12px 8px;
  }
}

.tile-image {
  border-radius: 9px;
  width: 100%;
}

.tile-heading {
  margin-top: 24px;
  margin-bottom: 6px;
  color: #090d4e;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 767px) {
    color: #090d4e;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 6px;
  }
}

.tile-desc {
  margin-bottom: 32px;
  color: #090d4e;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    color: #090d4e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

#bottom-cta {
  margin-top: 144px;

  @media screen and (max-width: 767px) {
    display: none; // do not display download cta on mobile devices
  }

  h1 {
    color: #090d4e;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
}

#footer {
  position: relative;
  margin-top: 100px;
  padding: 80px 40px 40px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(8px);
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 80px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  .footer-left {
    flex: 1;
    max-width: 360px;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      text-align: center;
    }
  }

  .footer-right {
    display: flex;
    gap: 80px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }
  }

  .footer-column {
    min-width: 160px;

    h4 {
      color: #090d4e;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 16px;
    }

    a {
      color: rgba(9, 13, 78, 0.7);
      text-decoration: none;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        color: #057cff;
      }
    }
  }

  #footer-logo {
    width: 106px;
    height: 32px;
    margin-bottom: 24px;
  }

  #footer-desc {
    color: rgba(9, 13, 78, 0.6);
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 32px;
  }

  .footer-social {
    display: flex;
    gap: 16px;
    margin-top: 24px;

    @media screen and (max-width: 767px) {
      justify-content: center;
    }

    a {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        background: #057cff;
        img {
          filter: brightness(0) invert(1);
        }
      }

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .footer-bottom {
    margin-top: 60px;
    padding-top: 24px;
    border-top: 1px solid rgba(9, 13, 78, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      text-align: center;
    }

    .footer-copyright {
      color: rgba(9, 13, 78, 0.5);
      font-size: 13px;
    }

    .footer-legal {
      display: flex;
      gap: 24px;

      a {
        color: rgba(9, 13, 78, 0.5);
        text-decoration: none;
        font-size: 13px;
        transition: color 0.2s ease;

        &:hover {
          color: #057cff;
        }
      }
    }
  }
}

.ant-menu {
  background-color: #d9eaff;
}

.ant-menu-item {
  color: #000 !important;
}

// .ant-menu-item:hover {
//   color: #666 !important;
// }

// /* remove the bottom border on antd menu item */
// .ant-menu-horizontal .ant-menu-item::after {
//   display: none;
// }

.video-container {
  position: relative;
  // overflow: hidden;
  width: 100%;
  // padding-bottom: 56.25%; /* aspect ratio 16:9 (9 / 16 * 100) */

  @media screen and (max-width: 767px) {
    border-radius: 0px;
    // margin: 0 16px;
  }
}

.video-container > video {
  position: relative;
  z-index: 2;
  border-radius: 24px;
  max-width: 100%;

  @media screen and (max-width: 767px) {
    border-radius: 12px;
    width: 90%;
  }
}

#login-page {
  margin-top: 8px;

  #login-state-display {
    div {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

.google-login-button {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  width: 100%;
  font-size: 15px;
  background-color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.google-login-button-icon {
  margin-right: 40px;
  width: 30px;
  height: 30px;
}

/* fixed footer */
#footer-login {
  display: none;
  // @media screen and (max-width: 767px) {
  //   display: block;
  //   position: fixed;
  //   box-sizing: border-box;
  //   bottom: 0;
  //   width: 100%;
  //   padding: 16px;
  //   background-color: #e5f1ff;
  //   text-align: center;
  //   transition: all 0.3s ease;
  //   z-index: 100;
  // }
}

.signup-button {
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  display: block;
  width: 100%;
  background-color: #057cff;
  color: white;
  height: 56px;
  box-sizing: border-box;
  padding: 4px 48px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 12px;
}

.signup-button-desc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expanded {
  padding: 20px;
}

.expanded-footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-input {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(9, 13, 78, 0.05);
  color: rgba(9, 13, 78, 0.3);
  font-size: 15px;
}

.divider-text {
  color: rgba(9, 13, 78, 0.3);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.close-button {
  position: absolute;
  top: -10px;
  right: 0px;
  background: #fff;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  transition: background 0.3s;
}
